import { deleteDoc, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { SOW } from "../Model/SOW";
import { sharedFirestore } from "./SharedFirebase";

declare global {
	interface Window {
		saveSOWsData: (sowsData: SOW[]) => Promise<void>;
		loadSOWsData: () => Promise<SOW[]>;
		emailPDF: (pdfData: Blob,fileName:string) => Promise<void>;
	}
}



export function saveSOWsData(sowData: SOW[]): Promise<void> {
	return window.saveSOWsData(sowData);
}

function removeUndefined(sowData: any) {
	let sow:any = JSON.parse(JSON.stringify(sowData));
	for(var i in sow){
		if(sow[i] === undefined){
			delete sow[i];
		}
		if(typeof sow[i] === 'object'){
			sow[i] = removeUndefined(sow[i]);
		}
	}
	return sow;
}

// export function loadSOWsData(): Promise<SOW[]> {
// 	return new Promise<SOW[]>((resolve, reject) => {
// 		window.loadSOWsData().then(sows =>{
// 			updateLoadedSOWs(sows).then((updatedSOWs) => {
// 				resolve(updatedSOWs);
// 			}).catch((error) => {
// 				reject(error);
// 			});
// 		});
// 	});
// }

export function sendEmail(pdfData: Blob, fileName:string): Promise<void> {
	return window.emailPDF(pdfData,fileName);
} 

export function saveSOW(sow: SOW): Promise<void> {
	return new Promise<void>((resolve, reject) => {
			let docRef = doc(sharedFirestore, "SOWs", sow.id);
			//partial update doc
			setDoc(docRef, removeUndefined(sow)).then(() => {
				resolve();
			}).catch((error) => {
				reject(error);
			});

		// loadSOWsData().then((sowsData: SOW[]) => {
		// 	let found = false;
		// 	for(var i =0; i<sowsData.length; i++){
		// 		if(sowsData[i].id === sow.id){
		// 			sowsData[i] = sow;
		// 			found = true;
		// 			break;
		// 		}
		// 	}
		// 	if(!found){
		// 		sowsData.push(sow);
		// 	}
		// 	saveSOWsData(sowsData).then(() => {
		// 		resolve();
		// 	}).catch((error) => {
		// 		reject(error);
		// 	});
		// }).catch((error) => {
		// 	reject(error);
		// });
	});
}

export function deleteSOW(sow: SOW): Promise<void> {
	return new Promise((resolve, reject) => {
		let docRef = doc(sharedFirestore, "SOWs", sow.id);
		// mark the doc as deleted but keep it in the collection
		updateDoc(docRef, {deleted: true}).then(() => {
			resolve();
		}).catch((error) => {
			reject(error);
		});

		// loadSOWsData().then((sowsData: SOW[]) => {
		// 	for(var i =0; i<sowsData.length; i++){
		// 		if(sowsData[i].id === sow.id){
		// 			sowsData.splice(i, 1);
		// 			break;
		// 		}
		// 	}
		// 	saveSOWsData(sowsData).then(() => {
		// 		resolve();
		// 	}).catch((error) => {
		// 		reject(error);
		// 	});
		// }).catch((error) => {
		// 	reject(error);
		// });
	});
}



// function updateLoadedSOWs(sows: SOW[]): Promise<SOW[]> {
// 	return new Promise((resolve, reject) => {
// 		//go through each configs scene object arrays and set the scale to 1 if undefined
// 		sows.forEach((sow) => {
// 			sow.configs.forEach((config) => {
// 				config.roomLayout.sceneObjects.forEach((sceneObject) => {
// 					if (sceneObject.letter === undefined) {
// 						sceneObject.letter = "";
// 					}
// 					if (sceneObject.scale === undefined) {
// 						sceneObject.scale = 1;
// 					}
// 					if(sceneObject.children == undefined){
// 						sceneObject.children = [];
// 					}
// 				});
// 				config.equipmentBoomLayout.sceneObjects.forEach((sceneObject) => {
// 					if (sceneObject.letter === undefined) {
// 						sceneObject.letter = "";
// 					}
// 					if (sceneObject.scale === undefined) {
// 						sceneObject.scale = 1;
// 					}
// 					if(sceneObject.children == undefined){
// 						sceneObject.children = [];
// 					}
// 				})
// 			})
// 		})
// 		saveSOWsData(sows).then(() => {
// 			resolve(sows);
// 		}).catch((error) => {
// 			reject(error);
// 		});
// 	});
// }